// import React from "react";

export const STATUS_TYPE = {
  active: '#2E7D32',
  inactive: 'red',
  pending: '#FBAA45'
};

export const FeedbackCardList = [
  {
    id: 1,
    title: 'What is a Region?',
    description:
      'This is the selected region where your Asio account will be created.',
    link: 'Click here to get help, if there is an issue with your Region.'
  },
  {
    id: 2,
    title: 'What is a Member Code',
    description: `Member code is a unique identifier to your Asio instance which can also be further used to have a sub-domain of other ConnectWise applications like ScreenConnect, BrightGauge, IT Boost.`,
    link: 'Click here to get help, if there is an issue with your Member Code.'
  },
  {
    id: 3,
    title: 'What to do If you do not see your instance?',
    description: `If you can't see your PSA instance, Contact support or admin: Get in touch with the people who manage the instance for help by raising a case.`,
    link: 'Click here to get help with your product instances.'
  },
  {
    id: 4,
    title: 'Why is my email address needed for setup?',
    description:
      'As the initiator for setting up the Asio instance, your email will be considered the first user and will be assigned the Primary Super User role in Asio. This role grants you full access to all features and functionalities.',
    link: 'Click here to get help if you are having issues with your email.'
  },
  {
    id: 5,
    title: 'Still need help?',
    description: `If you require assistance or have any further questions, please click on link below. Our dedicated support team is here to help you and will respond to your inquiry as soon as possible.`,
    link: 'Click here to raise a case, if you need additional help.'
  }
];

export const CurrencyCodeMapping = [
  { code: 'USDOLLAR', name: 'USD' },
  { code: 'EURO', name: 'EURO' },
  { code: 'POUND', name: 'Pound sterling' },
  { code: 'RUPEE', name: 'Indian Rupees' },
  { code: 'AUD', name: 'AUD' },
  { code: 'CAD', name: 'CAD' },
  { code: 'EURO', name: 'EUR' }
];
export const CountryCodeMapping = [
  { name: 'Afghanistan', code: 'US' },
  { name: 'Aland Islands', code: 'US' },
  { name: 'Albania', code: 'OE' },
  { name: 'Algeria', code: 'US' },
  { name: 'American Samoa', code: 'US' },
  { name: 'Andorra', code: 'OE' },
  { name: 'Angola', code: 'US' },
  { name: 'Anguilla', code: 'US' },
  { name: 'Antarctica', code: 'US' },
  { name: 'Antigua and Barbuda', code: 'US' },
  { name: 'Argentina', code: 'US' },
  { name: 'Armenia', code: 'US' },
  { name: 'Aruba', code: 'US' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'OE' },
  { name: 'Azerbaijan', code: 'US' },
  { name: 'Bahamas', code: 'US' },
  { name: 'Bahrain', code: 'US' },
  { name: 'Bangladesh', code: 'US' },
  { name: 'Barbados', code: 'US' },
  { name: 'Belgium', code: 'BL' },
  { name: 'Belize', code: 'US' },
  { name: 'Benin', code: 'US' },
  { name: 'Bermuda', code: 'US' },
  { name: 'Bhutan', code: 'US' },
  { name: 'Bolivia Plurinational State of', code: 'US' },
  { name: 'Bonaire, Sint Eustatius and Saba', code: 'US' },
  { name: 'Bosnia and Herzegovina', code: 'OE' },
  { name: 'Botswana', code: 'US' },
  { name: 'Bouvet Island', code: 'US' },
  { name: 'Brazil', code: 'US' },
  { name: 'British Indian Ocean Territory', code: 'US' },
  { name: 'Brunei Darussalam', code: 'US' },
  { name: 'Bulgaria', code: 'OE' },
  { name: 'Burkina Faso', code: 'US' },
  { name: 'Burundi', code: 'US' },
  { name: 'Cambodia', code: 'US' },
  { name: 'Cameroon', code: 'US' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'US' },
  { name: 'Cayman Islands', code: 'US' },
  { name: 'Central African Republic', code: 'US' },
  { name: 'Chad', code: 'US' },
  { name: 'Chile', code: 'US' },
  { name: 'China', code: 'US' },
  { name: 'Christmas Island', code: 'US' },
  { name: 'Cocos (Keeling) Islands', code: 'US' },
  { name: 'Colombia', code: 'US' },
  { name: 'Comoros', code: 'US' },
  { name: 'Congo', code: 'US' },
  { name: 'Cook Islands', code: 'US' },
  { name: 'Costa Rica', code: 'US' },
  { name: 'Croatia', code: 'OE' },
  { name: 'CuraÃƒÂ§ao', code: 'US' },
  { name: 'Cyprus', code: 'US' },
  { name: 'Czech Republic', code: 'OE' },
  { name: 'Denmark', code: 'OE' },
  { name: 'Djibouti', code: 'US' },
  { name: 'Dominica', code: 'US' },
  { name: 'Dominican Republic', code: 'US' },
  { name: 'Ecuador', code: 'US' },
  { name: 'Egypt', code: 'US' },
  { name: 'El Salvador', code: 'US' },
  { name: 'Equatorial Guinea', code: 'US' },
  { name: 'Eritrea', code: 'US' },
  { name: 'Estonia', code: 'OE' },
  { name: 'Ethiopia', code: 'US' },
  { name: 'Falkland Islands (Malvinas)', code: 'US' },
  { name: 'Faroe Islands', code: 'US' },
  { name: 'Fiji', code: 'US' },
  { name: 'Finland', code: 'OE' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'US' },
  { name: 'French Polynesia', code: 'US' },
  { name: 'French Southern Territories', code: 'US' },
  { name: 'Gabon', code: 'US' },
  { name: 'Gambia', code: 'US' },
  { name: 'Georgia', code: 'US' },
  { name: 'Germany', code: 'GR' },
  { name: 'Ghana', code: 'US' },
  { name: 'Gibraltar', code: 'US' },
  { name: 'Greece', code: 'OE' },
  { name: 'Greenland', code: 'US' },
  { name: 'Grenada', code: 'US' },
  { name: 'Guadeloupe', code: 'US' },
  { name: 'Guam', code: 'US' },
  { name: 'Guatemala', code: 'US' },
  { name: 'Guernsey', code: 'US' },
  { name: 'Guinea', code: 'US' },
  { name: 'Guinea-Bissau', code: 'US' },
  { name: 'Guyana', code: 'US' },
  { name: 'Haiti', code: 'US' },
  { name: 'Heard Island and McDonald Islands', code: 'US' },
  { name: 'Holy See (Vatican City State)', code: 'OE' },
  { name: 'Honduras', code: 'US' },
  { name: 'Hong Kong', code: 'US' },
  { name: 'Hungary', code: 'OE' },
  { name: 'Iceland', code: 'OE' },
  { name: 'India', code: 'US' },
  { name: 'Indonesia', code: 'US' },
  { name: 'Ireland', code: 'OE' },
  { name: 'Isle of Man', code: 'US' },
  { name: 'Israel', code: 'US' },
  { name: 'Italy', code: 'OE' },
  { name: 'Jamaica', code: 'US' },
  { name: 'Japan', code: 'US' },
  { name: 'Jersey', code: 'US' },
  { name: 'Jordan', code: 'US' },
  { name: 'Kazakhstan', code: 'US' },
  { name: 'Kenya', code: 'US' },
  { name: 'Kiribati', code: 'US' },
  { name: 'Korea, Republic of', code: 'US' },
  { name: 'Kuwait', code: 'US' },
  { name: 'Kyrgyzstan', code: 'US' },
  { name: "Lao People's Democratic Republic", code: 'US' },
  { name: 'Latvia', code: 'OE' },
  { name: 'Lesotho', code: 'US' },
  { name: 'Liberia', code: 'US' },
  { name: 'Libyan Arab Jamahiriya', code: 'US' },
  { name: 'Liechtenstein', code: 'OE' },
  { name: 'Lithuania', code: 'OE' },
  { name: 'Luxembourg', code: 'OE' },
  { name: 'Macedonia, the former Yugoslav Republic of', code: 'OE' },
  { name: 'Madagascar', code: 'US' },
  { name: 'Malawi', code: 'US' },
  { name: 'Malaysia', code: 'US' },
  { name: 'Maldives', code: 'US' },
  { name: 'Mali', code: 'US' },
  { name: 'Malta', code: 'OE' },
  { name: 'Marshall Islands', code: 'US' },
  { name: 'Martinique', code: 'US' },
  { name: 'Mauritania', code: 'US' },
  { name: 'Mauritius', code: 'US' },
  { name: 'Mayotte', code: 'US' },
  { name: 'Mexico', code: 'US' },
  { name: 'Micronesia, Federal State of', code: 'US' },
  { name: 'Moldova, Republic of', code: 'OE' },
  { name: 'Monaco', code: 'OE' },
  { name: 'Mongolia', code: 'US' },
  { name: 'Montenegro', code: 'OE' },
  { name: 'Montserrat', code: 'US' },
  { name: 'Morocco', code: 'US' },
  { name: 'Mozambique', code: 'US' },
  { name: 'Namibia', code: 'US' },
  { name: 'Nauru', code: 'US' },
  { name: 'Nepal', code: 'US' },
  { name: 'Netherlands', code: 'OE' },
  { name: 'New Caledonia', code: 'US' },
  { name: 'New Zealand', code: 'US' },
  { name: 'Nicaragua', code: 'US' },
  { name: 'Niger', code: 'US' },
  { name: 'Nigeria', code: 'US' },
  { name: 'Niue', code: 'US' },
  { name: 'Norfolk Island', code: 'US' },
  { name: 'Northern Mariana Islands', code: 'US' },
  { name: 'Norway', code: 'OE' },
  { name: 'Oman', code: 'US' },
  { name: 'Pakistan', code: 'US' },
  { name: 'Palestinian Territory, Occupied', code: 'US' },
  { name: 'Panama', code: 'US' },
  { name: 'Papua New Guinea', code: 'US' },
  { name: 'Paraguay', code: 'US' },
  { name: 'Peru', code: 'US' },
  { name: 'Philippines', code: 'US' },
  { name: 'Pitcairn', code: 'US' },
  { name: 'Poland', code: 'OE' },
  { name: 'Portugal', code: 'OE' },
  { name: 'Qatar', code: 'US' },
  { name: 'Reunion', code: 'US' },
  { name: 'Romania', code: 'OE' },
  { name: 'Russian Federation', code: 'OE' },
  { name: 'Rwanda', code: 'US' },
  { name: 'Saint BarthÃƒÂ©lemy', code: 'US' },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'US' },
  { name: 'Saint Kitts and Nevis', code: 'US' },
  { name: 'Saint Lucia', code: 'US' },
  { name: 'Saint Martin (French part)', code: 'US' },
  { name: 'Saint Pierre and Miquelon', code: 'US' },
  { name: 'Saint Vincent and the Grenadines', code: 'US' },
  { name: 'Samoa', code: 'US' },
  { name: 'San Marino', code: 'US' },
  { name: 'Sao Tome and Principe', code: 'US' },
  { name: 'Saudi Arabia', code: 'US' },
  { name: 'Senegal', code: 'US' },
  { name: 'Serbia', code: 'OE' },
  { name: 'Seychelles', code: 'US' },
  { name: 'Sierra Leone', code: 'US' },
  { name: 'Singapore', code: 'US' },
  { name: 'Sint Maarten (Dutch part)', code: 'US' },
  { name: 'Slovakia', code: 'OE' },
  { name: 'Slovenia', code: 'OE' },
  { name: 'Solomon Islands', code: 'US' },
  { name: 'South Africa', code: 'US' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'US' },
  { name: 'Spain', code: 'OE' },
  { name: 'Sri Lanka', code: 'US' },
  { name: 'Suriname', code: 'US' },
  { name: 'Svalbard and Jan Mayen', code: 'US' },
  { name: 'Swaziland', code: 'US' },
  { name: 'Sweden', code: 'OE' },
  { name: 'Switzerland', code: 'OE' },
  { name: 'Tajikistan', code: 'US' },
  { name: 'Tanzania, United Republic of', code: 'US' },
  { name: 'Thailand', code: 'US' },
  { name: 'Timor-Leste', code: 'US' },
  { name: 'Togo', code: 'US' },
  { name: 'Tokelau', code: 'US' },
  { name: 'Tonga', code: 'US' },
  { name: 'Trinidad and Tobago', code: 'US' },
  { name: 'Tunisia', code: 'US' },
  { name: 'Turkey', code: 'US' },
  { name: 'Turkmenistan', code: 'US' },
  { name: 'Turks and Caicos Islands', code: 'US' },
  { name: 'Tuvalu', code: 'US' },
  { name: 'Uganda', code: 'US' },
  { name: 'Ukraine', code: 'OE' },
  { name: 'United Arab Emirates', code: 'US' },
  { name: 'United Kingdom', code: 'UK' },
  { name: 'United States', code: 'US' },
  { name: 'Uruguay', code: 'US' },
  { name: 'Uzbekistan', code: 'US' },
  { name: 'Vanuatu', code: 'US' },
  { name: 'Venezuela, Bolivarian Republic of', code: 'US' },
  { name: 'Viet Nam', code: 'US' },
  { name: 'Virgin Islands, British', code: 'US' },
  { name: 'Wallis and Futuna', code: 'US' },
  { name: 'Western Sahara', code: 'US' },
  { name: 'Zambia', code: 'US' },
  { name: 'Canary Islands', code: 'OE' },
  { name: 'Ceuta and Melilla', code: 'OE' },
  { name: 'Chinese Taipei', code: 'US' },
  { name: 'Iraq', code: 'US' },
  { name: 'Kosovo', code: 'US' },
  { name: 'Myanmar', code: 'US' },
  { name: 'Yemen', code: 'US' }
];

export const Products = {
  AsioPlatform: 'Platform'
};
