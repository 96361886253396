import * as core from 'cw-ui-core';
import { myWatchedIssueFilters } from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesFilterConditionsContainer';
import KnownIssuesApi from 'src/services/Api/HomeApi/KnownIssuesService';
import { VIEW_KNOWN_ISSUE_DRAWER } from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesDrawer';
import { setCookie } from 'src/services/Utils';

const pageSize = 50;
const defaultPage = 1;

export const CLOSED_STATUS = 'Closed';
export const ARCHIVED_STATUS = 'Archived';
export const RELEASED_STATUS = 'Released';

export const PRODUCTS = 'products';
export const COMPONENTS = 'components';
export const FIXVERSION = 'fixVersion';
export const STATUSES = 'statuses';
export const MYWATCHEDISSUE = 'myWatchedIssues';

export const loadKnownIssues = async (pageIndex, filters = {}) => {
  const pageName = pageIndex ? pageIndex : defaultPage;
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const orderBy = core.getScreenData(state.screen, ['knownIssues', 'orderBy']);
  const sort = core.getScreenData(state.screen, ['knownIssues', 'sort']);
  dispatch(core.setScreenData(['knownIssues', 'isReady'], false));

  //reset to false after for each request
  dispatch(
    core.setScreenData(
      ['knownIssuesfilterSelection', 'isNoPartnerOwnedProducts'],
      false
    )
  );

  const result = await KnownIssuesApi.getknownIssues(
    pageName,
    pageSize,
    filters,
    orderBy,
    sort
  );
  if (!result.isSuccess) {
    const error = result.error;
    core.CwLog.error(error);
    return;
  }

  dispatch(
    core.setScreenData(['knownIssues', 'data'], result.response.knownIssues)
  );
  dispatch(
    core.setScreenData(['knownIssues', 'pagination'], result.response.metadata)
  );
  dispatch(
    core.setScreenData('knownIssueFilters', result.response.filterFields)
  );

  if (filters.isDefault) {
    let partnerProducts = result?.response?.partnerProducts;
    if (
      partnerProducts &&
      result?.response?.filterFields?.products &&
      partnerProducts.length === result.response.filterFields.products.length
    ) {
      partnerProducts.unshift(-1);
    }

    dispatch(core.removeScreenData('knownIssuesfilterSelection'));
    dispatch(
      core.setScreenData(
        ['knownIssuesfilterSelection', 'partnerProducts'],
        partnerProducts
      )
    );

    //if no partnerproducts then we dont need to set statuses
    if (result.response.partnerProducts) {
      let knownIssuesStatusFilters = result.response.filterFields.statuses
        ? result.response.filterFields.statuses.map(item => {
            return item.name;
          })
        : [];

      const defaultStatuses = [
        'Backlog',
        'Development',
        'Testing',
        'Pending Release',
        'Investigating'
      ];
      knownIssuesStatusFilters = knownIssuesStatusFilters.filter(item =>
        defaultStatuses.includes(item)
      );

      const produtFliters = {
        products: partnerProducts,
        statuses: knownIssuesStatusFilters
      };
      const userId = getManageUserId();
      setCookie(
        'issues-user-default-filter' + userId,
        encodeURIComponent(JSON.stringify(produtFliters)),
        365 * 100
      );

      dispatch(
        core.setScreenData(
          ['knownIssuesfilterSelection', 'partnerStatuses'],
          knownIssuesStatusFilters
        )
      );
    } else {
      dispatch(
        core.setScreenData(
          ['knownIssuesfilterSelection', 'isNoPartnerOwnedProducts'],
          true
        )
      );
    }
  }

  dispatch(core.setScreenData(['knownIssues', 'isReady'], true));
};

export const loadKnownIssuesFilters = async () => {
  const dispatch = core.Store().dispatch;
  const result = await KnownIssuesApi.getknownIssuesFilters();
  if (!result.isSuccess) {
    const error = result.error;
    core.CwLog.error(error);
    return;
  }

  dispatch(
    core.setScreenData(['knownIssues', 'knownIssueFilters'], result.response)
  );
};

export const getKnownIssueById = async id => {
  const result = await KnownIssuesApi.getKnownIssueById(id);
  let isUnauthorized = false;
  if (result && !result.isSuccess) {
    if (result.status === 401 || result.status === 500) {
      isUnauthorized = true;
    } else {
      const error = result.error;
      core.CwLog.error(error);
      return;
    }
  }

  const knownIssueResponse = {
    result: result.response,
    isUnauthorized
  };

  return knownIssueResponse;
};

export const onSortClick = (columnNo, columnName, sort) => {
  let activeColumnForSorting = [false, false, false, false, false, false];
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  activeColumnForSorting[columnNo] = true;
  dispatch(
    core.setScreenData(
      ['knownIssues', 'sortingColumnStatus'],
      activeColumnForSorting
    )
  );
  dispatch(core.setScreenData(['knownIssues', 'orderBy'], columnName));
  dispatch(core.setScreenData(['knownIssues', 'sort'], sort ? 'desc' : 'asc'));

  let partnerProducts = core.getScreenData(state.screen, [
    'knownIssuesfilterSelection',
    'partnerProducts'
  ]);
  partnerProducts = partnerProducts ? partnerProducts.toJS() : [];

  let componentFilter = core.getScreenData(state.screen, [
    'knownIssuesfilterSelection',
    'partnerComponents'
  ]);
  componentFilter = componentFilter ? componentFilter.toJS() : [];

  let statusFilter = core.getScreenData(state.screen, [
    'knownIssuesfilterSelection',
    'partnerStatuses'
  ]);
  statusFilter = statusFilter ? statusFilter.toJS() : [];

  let fixVersionFilter = core.getScreenData(state.screen, [
    'knownIssuesfilterSelection',
    'partnerFixVersion'
  ]);
  fixVersionFilter = fixVersionFilter ? fixVersionFilter.toJS() : [];

  let myWatchedIssuesFilter = core.getScreenData(state.screen, [
    'knownIssuesfilterSelection',
    'myWatchedIssues'
  ]);
  myWatchedIssuesFilter = myWatchedIssuesFilter
    ? myWatchedIssuesFilter.toJS()
    : [];

  let searchBy = core.getScreenData(state.screen, [
    'knownIssuesfilterSelection',
    'searchText'
  ]);

  const filters = {
    products: composeFilterSelectedItems(partnerProducts, PRODUCTS),
    components: composeFilterSelectedItems(componentFilter, COMPONENTS),
    statuses: composeFilterSelectedItems(statusFilter, STATUSES),
    myWatchedIssues: composeFilterSelectedItems(
      myWatchedIssuesFilter,
      MYWATCHEDISSUE
    ),
    fixVersions: composeFilterSelectedItems(fixVersionFilter, FIXVERSION),
    searchBy,
    isDefault: false
  };

  loadKnownIssues(defaultPage, filters);
};

export const followKnownIssue = knownIssue => {
  knownIssue.isFollowedByUser = true;
  knownIssue.follows = knownIssue.follows + 1;
  return knownIssue;
};

export const unfollowKnownIssue = knownIssue => {
  knownIssue.isFollowedByUser = false;
  knownIssue.follows = knownIssue.follows - 1;
  return knownIssue;
};

export const getManageUserId = () => {
  const state = core.Store().getState();
  const userId = core.getUserId(state.oidc);
  return userId;
};

export const onFollowKnownIssueInListClicked = async (knownIssue, notify) => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const userId = core.getUserId(state.oidc);
  let knownIssues = core.getScreenData(state.screen, ['knownIssues', 'data']);
  knownIssues = knownIssues ? knownIssues.toJS() : [];
  const selectedKnownIssueIndex = knownIssues.findIndex(
    x => x.jiraId === knownIssue.jiraId
  );

  if (selectedKnownIssueIndex > -1) {
    if (knownIssue.isFollowedByUser) {
      knownIssues[selectedKnownIssueIndex] = unfollowKnownIssue(
        knownIssues[selectedKnownIssueIndex]
      );
      dispatch(core.setScreenData(['knownIssues', 'data'], knownIssues));
      const result = await KnownIssuesApi.deleteFollowKnownIssue(
        knownIssue.jiraId,
        userId
      );
      if (result.isSuccess) {
        return;
      } else {
        knownIssues[selectedKnownIssueIndex] = followKnownIssue(
          knownIssues[selectedKnownIssueIndex]
        );
        dispatch(core.setScreenData(['knownIssues', 'data'], knownIssues));
        const error = result.error;
        core.CwLog.error(error);
        return;
      }
    } else {
      knownIssues[selectedKnownIssueIndex] = followKnownIssue(
        knownIssues[selectedKnownIssueIndex]
      );
      dispatch(core.setScreenData(['knownIssues', 'data'], knownIssues));
      const result = await KnownIssuesApi.followKnownIssue(
        knownIssue.jiraId,
        userId,
        notify
      );
      if (result.isSuccess) {
        return;
      } else {
        knownIssues[selectedKnownIssueIndex] = unfollowKnownIssue(
          knownIssues[selectedKnownIssueIndex]
        );
        dispatch(core.setScreenData(['knownIssues', 'data'], knownIssues));
        const error = result.error;
        core.CwLog.error(error);
        return;
      }
    }
  }
};

export const composeFilterSelectedItems = (selectedItems, filterType) => {
  const state = core.Store().getState();
  let knownIssuesFilters = core.getScreenData(state.screen, [
    'knownIssueFilters'
  ]);
  knownIssuesFilters = knownIssuesFilters ? knownIssuesFilters.toJS() : [];

  let selectedOptions = selectedItems ? selectedItems : [];
  let alloptions = [];
  if (selectedOptions[0] === -1) {
    if (filterType === PRODUCTS) {
      alloptions = knownIssuesFilters.products;
    } else if (filterType === COMPONENTS) {
      alloptions = knownIssuesFilters.components;
    } else if (filterType === STATUSES) {
      alloptions = knownIssuesFilters.statuses;
    } else if (filterType === FIXVERSION) {
      alloptions = knownIssuesFilters.fixVersions;
    } else if (filterType === MYWATCHEDISSUE) {
      alloptions = myWatchedIssueFilters;
    }
  }

  if (alloptions) {
    alloptions = alloptions.map(item => {
      return item.id;
    });
  }

  return selectedOptions[0] === -1 ? alloptions : selectedOptions;
};

export const onFollowKnownIssueInDrawerClicked = async notify => {
  const state = core.Store().getState();
  const userId = core.getUserId(state.oidc);
  const dispatch = core.Store().dispatch;
  let knownIssue = core.getScreenData(
    state.screen,
    ['knownIssueDetails', 'result'],
    VIEW_KNOWN_ISSUE_DRAWER
  );
  knownIssue = knownIssue ? knownIssue.toJS() : {};

  const result = await KnownIssuesApi.followKnownIssue(
    knownIssue.jiraId,
    userId,
    notify
  );

  if (result.isSuccess) {
    updateKnownIssuesStoreList(knownIssue.jiraId, true);
  } else {
    knownIssue = unfollowKnownIssue(knownIssue);
    dispatch(
      core.setScreenData(
        ['knownIssueDetails', 'result'],
        knownIssue,
        VIEW_KNOWN_ISSUE_DRAWER
      )
    );
    const error = result.error;
    core.CwLog.error(error);
  }
};

export const updateKnownIssuesStoreList = (jiraId, isFollowedByUser) => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  let knownIssuesData = core.getScreenData(state.screen, [
    'knownIssues',
    'data'
  ]);
  knownIssuesData = knownIssuesData ? knownIssuesData.toJS() : [];
  if (knownIssuesData.length > 0) {
    const objIndex = knownIssuesData.findIndex(obj => obj.jiraId === jiraId);
    if (objIndex >= 0) {
      if (isFollowedByUser) {
        knownIssuesData[objIndex].follows += 1;
      } else {
        knownIssuesData[objIndex].follows -= 1;
      }
    }

    knownIssuesData[objIndex].isFollowedByUser = isFollowedByUser;
  }

  dispatch(core.setScreenData(['knownIssues', 'data'], knownIssuesData));
};

export const sendEmailLink = async (id, key, summary, email) => {
  const result = await KnownIssuesApi.sendEmailLink(id, email, key, summary);
  if (!result.isSuccess) {
    const error = result.error;
    core.CwLog.error(error);
  } else {
    if (result.response === false) {
      core.CwLog.error('The Email hasn`t been sent, please try again.');
    }
  }
};
