import React from 'react';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import css from 'src/components/Drawer/AddLicenseDrawer/DrawerContent/DrawerContent.scss';
import cx from 'classnames';

const DrawerContentError = props => {
  const licenseNameLowercase = props.licenseName
    ? props.licenseName.toLowerCase()
    : 'licenses';

  return (
    <core.Flexbox column className={cx(css.licenseInfo, css.licenseInfoError)}>
      {!props.processingError && !props.ticketProcessingError ? (
        <>
          <core.Label
            value={core.formatMessage(Locale.add_license_quote, {
              licenseName: licenseNameLowercase
            })}
            className={css.labelQuote}
          />
        </>
      ) : (
        <>
          <core.Flexbox className={css.licenseSuccessimage}>
            <core.Image
              src="/images/HomePage/undraw-empty-xct-9.png"
              isInlineSVG={false}
              alt={core.formatMessage(Locale.license_error)}
            />
          </core.Flexbox>
          {props.processingError && (
            <core.Label
              value={core.formatMessage(Locale.license_not_purchased, {
                licenseName: licenseNameLowercase
              })}
              className={cx(css.labelQuote, {
                [css.labelContactManager]: props.processingError
              })}
            />
          )}
          {props.ticketProcessingError && (
            <core.Label
              value={core.formatMessage(Locale.ticket_not_created, {})}
              className={cx(css.labelQuote, {
                [css.labelContactManager]: props.ticketProcessingError
              })}
            />
          )}
        </>
      )}
    </core.Flexbox>
  );
};

export default core.MobileLayoutHOC(DrawerContentError);
