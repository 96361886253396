import * as core from 'cw-ui-core';
import AsioSelfService from './Api/AsioApi/AsioSelfService';
import { FEEDBACK_DIALOG_ID } from 'src/components/AsioEnablement/Feedback/FeedbackDialogContainer';
import { ASIO_ENABLEMENT_DIALOG_ID } from 'src/components/AsioEnablement/AsioEnablementDialog/AsioEnablementDialogContainer';
import { CountryCodeMapping, CurrencyCodeMapping } from 'src/utils';

export const getRegion = async () => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const region = core.getModuleCache(state.module, ['partner', 'region']);
  if (region === undefined || region === null) {
    const result = await AsioSelfService.getPartnerRegion();
    dispatch(core.setModuleCache(['partner', 'region'], result.response));
  }
};

export const provisionAsio = async () => {
  let companyName;
  const state = core.Store().getState();
  const asioProductId = core.getModuleConfig(state.module, ['asioProductId']);
  const asioMemberCode = core.getScreenData(
    state.screen,
    ['memberCode'],
    ASIO_ENABLEMENT_DIALOG_ID
  );
  const userEmail = core.getUserEmail(state.oidc);
  const partnerModule = core.getModuleCache(state.module, ['partner']);
  const partner = partnerModule ? partnerModule.toJS() : undefined;
  const firstName = core.getUserFirstName(state.oidc);
  const company = core.getModuleCache(state.module, ['company']).toJS();
  let partnerCompany = core.getModuleCache(state.module, [
    'company',
    'companyName'
  ]);
  if (partnerCompany) {
    companyName = partnerCompany.replace(/[^a-zA-Z0-9-]/g, '');
  }

  const currencyCode = CurrencyCodeMapping.find(
    code => code.name === partner.currency
  );
  const currency = currencyCode ? currencyCode.code : 'USDOLLAR';
  const countryCode = CountryCodeMapping.find(
    code => code.name === partner.country
  );
  const country = countryCode ? countryCode.code : 'US';
  let provisioningDetails = {
    productId: asioProductId,
    email: userEmail,
    agreementAcknowledged: true,
    signupData: {
      confirmLogonEmail: userEmail,
      country: partner.country,
      cwPartnerId: partner.id,
      logonEmail: userEmail,
      memberBillingCurrency: currency,
      memberCode: asioMemberCode ? asioMemberCode : companyName,
      memberCountry: country,
      memberCreatedBy: userEmail,
      memberName: partner.companyName,
      telephoneNo: partner.phoneNumber,
      mobileNo: partner.phoneNumber,
      salesForceReferenceAccountID: company.sfAccountNumber,
      userFirstName: firstName
    },
    instanceData: {
      paymentStatus :{
        identifier: "free"
      }
    }
  };
  let result = await AsioSelfService.provisionAsio(provisioningDetails);
  return result;
};

export const submitFeedback = async () => {
  const state = core.Store().getState();
  const caseConstant = 'Asio Self-service in CW Home Error';
  const userId = core.getUserId(state.oidc);
  const subjectIssue = core.getScreenData(
    state.screen,
    ['issueTitle'],
    FEEDBACK_DIALOG_ID
  );
  const caseDescription = core.getScreenData(
    state.screen,
    ['issueSummary'],
    FEEDBACK_DIALOG_ID
  );
  let feedbackData = {
    useCase: caseConstant,
    contactSsoId: userId,
    subject: subjectIssue,
    description: caseDescription
  };
  let result = await AsioSelfService.submitFeedback(feedbackData);
  return result;
};

export const validateProvisioningData = async () => {
  let companyName;
  const state = core.Store().getState();
  const asioMemberCode = core.getScreenData(
    state.screen,
    ['memberCode'],
    ASIO_ENABLEMENT_DIALOG_ID
  );
  let partnerCompany = core.getModuleCache(state.module, [
    'company',
    'companyName'
  ]);
  if (partnerCompany) {
    companyName = partnerCompany.replace(/[^a-zA-Z0-9-]/g, '');
  }

  let memberCode = asioMemberCode ? asioMemberCode : companyName;
  
  let result = await AsioSelfService.validate(memberCode);
  return result;
};

const fetchDataAndDispatch = async (fetchFunction, cacheKey) => {
  const dispatch = core.Store().dispatch;
  try {
    const result = await fetchFunction();
    if (!result.isSuccess) {
      throw new Error(result.error);
    }

    dispatch(core.setModuleCache(cacheKey, result.response));
  } catch (error) {
    core.CwLog.error(error);
  }
};

export const getWhatsNextData = async () => {
  await fetchDataAndDispatch(AsioSelfService.getWhatsNextData, ['asioSelfService', 'whatsNextData']);
};

export const getVideoTutorialsData = async () => {
  await fetchDataAndDispatch(AsioSelfService.getVideoTutorialsData, ['asioSelfService', 'videoAndTutorialsData']);
};
