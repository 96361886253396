import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import Routes from 'src/services/Routes';
import SwitchAccountService from 'src/services/Api/HomeApi/SwitchAccountService';

export const switchAccount = async () => {
  sessionStorage.clear();  // To kill the existing access token.
  const state = core.Store().getState();
  const homeBaseUrl = core.getModuleConfig(state.module, ['homeUrl']);
  const stsBaseUrl = core.getModuleConfig(state.module, ['authority']);
  window.location.replace(
    stsBaseUrl +
      '/account/selectaccount?clientId=cwhome_code&returnUrl=' +
      homeBaseUrl +
      '&setDefault=true'
  );
};

export const returnToPrimaryAccount = async () => {
  sessionStorage.clear();  // To kill the existing access token.
  const state = core.Store().getState();
  const user = core.getUser(state.oidc);
  const partnerId = user.toJS()?.profile?.origin_partner_id;
  const homeBaseUrl = core.getModuleConfig(state.module, ['homeUrl']);
  const stsBaseUrl = core.getModuleConfig(state.module, ['authority']);
  window.location.replace(
    stsBaseUrl +
      '/account/switch?clientId=cwhome_code&accountId=' +
      partnerId +
      '&redirectUri=' +
      homeBaseUrl +
      '&setDefault=true'
  );
};

export const isParentAccount = async (screenKey=Routes.HOME_PAGE_NOTIFICATION.id) => {
  const state = core.Store().getState();
  const user = core.getUser(state.oidc);
  const dispatch = core.Store().dispatch;
  const partnerId = core.getPartnerId(state.oidc);
  const name = core.getUserName(state.oidc);
  var result = await SwitchAccountService.getParent(partnerId);
  const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;
  if (isGuestUserLoggedIn) {
    dispatch(
      core.setWarningScreenMessage(
        core.formatMessage(Locale.sub_account_banner_description, {
          guestUser: name,
          subAccount: result.response.name,
          subAccountId: core.getPartnerId(state.oidc)
        }),
        true,
        [],
        screenKey
      )
    );
  }

  if (result.isSuccess) {
    if (Object.keys(result.response.parent || {})?.length > 0) {
      dispatch(
        core.setModuleCache(['childAccountCompanyName'], result.response.name)
      );
      dispatch(core.setModuleCache(['isParentAccount'], true));
    } else {
      dispatch(core.setModuleCache(['isParentAccount'], false));
    }
  }
};

export const isChildAccount = async () => {
  const state = core.Store().getState();
  const dispatch = core.Store().dispatch;
  const partnerId = core.getPartnerId(state.oidc);
  var result = await SwitchAccountService.getChildren(partnerId);
  if (result.isSuccess) {
    if (result.response.children?.length > 0) {
      dispatch(core.setModuleCache(['isChildAccount'], true));
    } else {
      dispatch(core.setModuleCache(['isChildAccount'], false));
    }
  }
};
