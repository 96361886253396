import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import App from './App';
import {
  checkIfEmailVerified,
  getPartnerAccountRequiredFields,
  isRequiredFieldsMissingSaved,
  lookupCompany
} from 'src/services/Partners/PartnersService';
import {
  getUserSecurity,
  userHasNoAccess,
  setWalkMeVariables,
  getSearchToken
} from 'src/services/User/UserService';
import { APP_PREREQUISITES_DIALOG } from 'src/DialogFactory';
import HasAccess from 'src/services/RoleAccess';
import Routes from 'src/services/Routes';
import AccessDeniedPage from 'src/components/AccessDeniedPage/AccessDeniedPage';
import { POLICIES } from 'src/services/Authorization';
import { getFeatures } from 'src/services/Features/FeaturesService';
import {
  getProducts,
  getPartnerProductsPurchased
} from 'src/services/Products/ProductsService';
import { preloadGlobalPreferenceUrl } from '../UserAccount/GlobalPreference';
import { getPublishedBanners } from 'src/services/Banner/BannerService';
import UserProfileService from 'src/services/Api/UserManagementApi/UserProfileService';
import { isChildAccount, isParentAccount } from 'src/components/UserAccount/SwitchAccount';
import { getApplicationRoles } from 'src/services/User/UserService';

const initialize = async () => {
  await getInActivityTime();
  await isChildAccount();
  await isParentAccount();
  await lookupCompany();
  await getSearchToken();
  await preloadGlobalPreferenceUrl();
  await getAndDispatchFeatures();
  await getProducts();
  await getApplicationRoles('platform');
};

const checkPartnerAccountRequiredFields = async () => {
  const dispatch = core.Store().dispatch;
  if (HasAccess([POLICIES.ADMIN, POLICIES.BILLING_ADMIN], null)) {
    const hasRequiredFields = await getPartnerAccountRequiredFields();
    if (hasRequiredFields) {
      dispatch(
        core.showDialog(APP_PREREQUISITES_DIALOG, {
          callback: initialize
        })
      );
      return;
    }
  } else {
    await getPartnerAccountRequiredFields();
  }

  await initialize();
};

const getInActivityTime = async () => {
  const dispatch = core.Store().dispatch;
  var result = await UserProfileService.getInactivityTime();
  if (result.isSuccess) {
    dispatch(core.setModuleCache(['inactivityDuration'], result.response.inActivityTiming));
    dispatch(core.setModuleCache(['timeDuration'], result.response.timerTiming));
  }
};

const getAndDispatchFeatures = async () => {
  const dispatch = core.Store().dispatch;
  const features = await getFeatures();
  const publishedBanners = await getPublishedBanners();
  let featuresObj;
  if (features) {
    featuresObj = features.reduce((acc, item) => {
      return {
        ...acc,
        [item.featureId]: item
      };
    }, {});
  }

  dispatch(core.setModuleCache(['features'], featuresObj));
  dispatch(core.setModuleCache(['banners'], publishedBanners));
};

const mapStateToProps = state => {
  const partnerProducts = getPartnerProductsPurchased();

  const showNowIconInMenu =
    partnerProducts.filter(
      product =>
        product.productIdentifier === 'manage' ||
        product.productIdentifier === 'automate' ||
        product.productIdentifier === 'sell' ||
        product.productIdentifier === 'control'
    ).length > 0;

  const showMenu = core.getScreenCustom(state.screen, ['showMenu']);
  const emailConfirmed = core.getModuleCache(state.module, [
    'user',
    'emailConfirmed'
  ]);
  let haveRequiredFields = true; // CWH-487 - Assume the required fields exist for all users.
  if (HasAccess([POLICIES.ADMIN, POLICIES.BILLING_ADMIN], null)) {
    haveRequiredFields = isRequiredFieldsMissingSaved();
  }

  const featuresChk = core.getModuleCache(state.module, ['features']);
  const isReady = haveRequiredFields && emailConfirmed && featuresChk;
  window.WalkMeVariables = setWalkMeVariables();

  const configuredRoutes = () =>
    Object.keys(Routes).filter(
      key => !Routes[key].configured || Routes[key].configured()
    );

  const isRouteAllowed = route =>
    HasAccess(
      route.allowedRoles && route.allowedRoles(),
      route.disallowedRoles && route.disallowedRoles()
    ) && HasAccess(route.mustHaveRoles && route.mustHaveRoles());

  const getRouteList = () => {
    let routeList = configuredRoutes().map(key => {
      const route = Routes[key];
      if (isRouteAllowed(route)) {
        if (key === 'NOW_PAGE') {
          route.showInMenu = showNowIconInMenu;
        }

        return route;
      }

      return {
        ...route,
        component: AccessDeniedPage,
        showInMenu: false
      };
    });

    const state = core.Store().getState();
    const user = core.getUser(state.oidc);
    const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;
    if (isGuestUserLoggedIn) {
        routeList = routeList.filter(
        item => item.id !== 'PartnerSupportRedirect' && item.id !== 'university_page'
      );
    }

    return routeList;
  };

  const isChild = core.getModuleCache(state.module, ['isChildAccount']);
  const isParent = core.getModuleCache(state.module, ['isParentAccount']);
  const user = core.getUser(state.oidc);
  const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;

  return {
    showMenu: showMenu === undefined ? true : showMenu,
    isAppReady: isReady ? true : false,
    routeList: isReady ? getRouteList() : {},
    isChild,
    isParent,
    isGuestUserLoggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDidMount: async () => {
      dispatch(core.setScreenCustom(['showMenu'], true));
      const userSecurity = await getUserSecurity();
      if (!userSecurity) {
        return;
      }

      if (userHasNoAccess()) {
        return;
      }

      const emailVerified = await checkIfEmailVerified();
      if (!emailVerified) {
        dispatch(
          core.showDialog('EMAIL_VERIFICATION', {
            callback: checkPartnerAccountRequiredFields
          })
        );
        return;
      }

      await checkPartnerAccountRequiredFields();
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
