import {
  getModuleCache,
  setModuleCache,
  getScreenData,
  getUserEmail
} from 'cw-ui-core';
import { connect } from 'react-redux';
import AsioFirstStep from 'src/components/AsioEnablement/AsioEnablementDialog/AsioFirstStep';
import { ASIO_ENABLEMENT_DIALOG_ID } from './AsioEnablementDialogContainer';

const mapStateToProps = state => {
  let companyName;
  let region = getModuleCache(state.module, ['partner', 'region']);
  let partnerCompany = getModuleCache(state.module, ['company', 'companyName']);
  if (partnerCompany) {
    companyName = partnerCompany.replace(/[^a-zA-Z0-9-]/g, '');
  }

  let errMessages = getModuleCache(state.module, ['provisioningResult', 'memberCode']);

  return {
    companyName,
    email: getUserEmail(state.oidc),
    region,
    asioMemberCode: getScreenData(
      state.screen,
      ['memberCode'],
      ASIO_ENABLEMENT_DIALOG_ID
    ),
    errors: errMessages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClosedIconClick: () => {
      dispatch(setModuleCache('provisioningResult', null));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AsioFirstStep);
