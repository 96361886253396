import cx from 'classnames';
import {
  DynamicMultiSelect,
  Flexbox,
  FlyoutComposer,
  formatMessage,
  IconPath,
  Image,
  Label,
  MobileLayoutHOC,
  TextField,
  ButtonStandard,
  ButtonLight,
  getScreenData,
  Store
} from 'cw-ui-core';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { getCookie, setCookie } from 'src/services/Utils';
import css from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesFilterConditions.scss';
import Locale from 'src/Locale';
import {
  COMPONENTS,
  composeFilterSelectedItems,
  FIXVERSION,
  getManageUserId,
  MYWATCHEDISSUE,
  PRODUCTS,
  STATUSES
} from 'src/services/Tickets/KnownIssuesService';
import formCss from 'src/styles/form.scss';
import svgCss from 'src/styles/svg.scss';
import { useDebouncedCallback } from 'use-debounce';
/** @typedef {import('src/components/PartnerSupportPage/KnownIssues/KnownIssuesFilterConditionsContainer').IssuesFilterDefinition} IssuesFilterDefinition */

const DesktopKnownISsuesFilterConditions = props => {
  const { activeFilter, ticketFlyoutDismissContext, onFilterIconClick } = props;
  const state = Store().getState();
  const isGridLoaded = getScreenData(state.screen, ['knownIssues', 'isReady']);
  return (
    <Flexbox verticalAlign className={css.desktopFilterContainer}>
      <FlyoutComposer
        automationName="knownIssuesFilterFlyout"
        widget={
          <div
            className={cx({
              [css.activeFilter]: activeFilter,
              [css.noClick]: !isGridLoaded
            })}
          >
            <Image
              automationName={
                activeFilter ? 'activeFilterCyan' : 'activeFilterBlack'
              }
              onClick={onFilterIconClick}
              className={cx(
                { [svgCss.cyan]: activeFilter },
                { [svgCss.black]: !activeFilter },
                svgCss.actionState
              )}
              src={IconPath.get('Action_Filter')}
            />
            <span></span>
          </div>
        }
        flyout={
          <div
            automationName="supportFlyout"
            className={css.supportFlyoutContainer}
          >
            {ticketFlyoutDismissContext}
          </div>
        }
      />
      <TextField
        automationName="knownIssueTicketSearch"
        maxLength={100}
        className={cx(css.searchIconKnownIssue, formCss.textField)}
        placeholder={formatMessage(Locale.search_known_issues_placeholder)}
        value={props.searchText}
        onChange={props.onSearchTextChange}
      />
      <FlyoutComposer
        className={css.flyoutContainer}
        automationName="knownIssuesHelpFlyout"
        widget={
          <Image
            cwId="knownIssueHelpIcon"
            alt="Help"
            title="Help"
            automationName="knownIssueHelpIcon"
            src={IconPath.get('Application_Help')}
          />
        }
        flyout={
          <Flexbox column className={css.filterContent}>
            <Label
              automationName="knownIssuesSubHelpText"
              value={formatMessage(Locale.known_issues_sub_helptext)}
              className={css.subhelptext}
            />
            <Label
              automationName="knownIssuesHelpText"
              value={formatMessage(Locale.known_issues_helptext)}
            />
            <Label
              automationName="knownIssuesHelpLink"
              className={css.helplink}
              hyperlink
              value={formatMessage(Locale.known_issues_helpLink)}
              onClick={props.onHelpLinkClick}
            ></Label>
          </Flexbox>
        }
      />
    </Flexbox>
  );
};

DesktopKnownISsuesFilterConditions.propTypes = {
  activeFilter: PropTypes.bool,
  availableFilters: PropTypes.object,
  onFilterIconClick: PropTypes.func,
  isShowTicketFilter: PropTypes.bool,
  ticketFlyoutDismissContext: PropTypes.object,
  onHelpLinkClick: PropTypes.func,
  onSearchTextChange: PropTypes.func,
  selectedFilter: PropTypes.object,
  searchText: PropTypes.string
};

const MobileKnownIssuesFilterConditions = props => {
  const {
    activeFilter,
    isShowTextFilter,
    isMobileLayout,
    onFilterIconClick,
    ticketFlyoutDismissContext
  } = props;
  return (
    <Flexbox className={cx({ [css.mobileInDesktop]: isMobileLayout })}>
      <Flexbox
        className={cx(css.filterMobile, {
          [css.fixedSearch]: isShowTextFilter
        })}
      >
        <Flexbox>
          <FlyoutComposer
            automationName="knownIssuesFilterFlyout"
            widget={
              <div className={cx({ [css.activeFilter]: activeFilter })}>
                <Image
                  automationName={
                    activeFilter ? 'activeFilterCyan' : 'activeFilterBlack'
                  }
                  onClick={onFilterIconClick}
                  className={cx(
                    { [svgCss.cyan]: activeFilter },
                    { [svgCss.black]: !activeFilter },
                    {
                      [css.hideSearch]: isShowTextFilter
                    }
                  )}
                  src={IconPath.get('Action_Filter')}
                />
                <span></span>
              </div>
            }
            flyout={
              <div
                automationName="supportFlyout"
                className={css.supportFlyoutContainer}
              >
                {ticketFlyoutDismissContext}
              </div>
            }
          />
        </Flexbox>
        <Flexbox className={css.filterSearch}>
          <Image
            automationName="filterSearch"
            onClick={props.onSearchIconClickInMobile}
            className={cx(svgCss.black, svgCss.actionState)}
            src={IconPath.get('Action_Search')}
          />
          {props.isShowTextFilter && (
            <Flexbox className={css.mobileSearchContainer}>
              <Image
                className={cx(
                  svgCss.cyan,
                  svgCss.actionState,
                  css.fixedSearchIcon
                )}
                src={IconPath.get('Action_Search')}
              />
              <TextField
                cwId="knownIssueticketSearch"
                automationName="knownIssueticketSearch"
                maxLength={100}
                className={cx(formCss.input, css.mobileSearch)}
                pickerIcon={IconPath.get('Action_Search')}
                placeholder={formatMessage(
                  Locale.search_known_issues_placeholder
                )}
                value={props.searchText}
                onChange={props.onSearchTextChange}
              />
              <Image
                automationName="searchQueryReset"
                onClick={() => {
                  props.onSearchTextChange('');
                }}
                className={cx(css.mobileSearchReset, svgCss.mediumGray)}
                src={IconPath.get('Action_Deny')}
              />
              <Image
                automationName="searchQueryClose"
                onClick={props.onSearchQueryClose}
                className={cx(css.mobileSearchClose, svgCss.mediumGray)}
                src={IconPath.get('Action_Close')}
              />
            </Flexbox>
          )}
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

MobileKnownIssuesFilterConditions.propTypes = {
  activeFilter: PropTypes.bool,
  onFilterIconClick: PropTypes.func,
  isShowTicketFilter: PropTypes.bool,
  ticketFlyoutDismissContext: PropTypes.object,
  onSearchTextChange: PropTypes.func,
  onSearchQueryClose: PropTypes.func,
  onSearchIconClickInMobile: PropTypes.func,
  searchText: PropTypes.string,
  isShowTextFilter: PropTypes.bool,
  isMobileLayout: PropTypes.bool
};

const FilterContent = props => {
  const {
    savedFilterState,
    knownIssuesProductFilters,
    handleProductSelectionChange,
    componentFilters,
    handleComponentSelectionChange,
    knownIssuesStatusFilters,
    handleStatusSelectionChange,
    fixVersionFilters,
    handleFixVersionSelectionChange,
    myWatchedIssueFilters,
    handleMyWatchedIssuesSelectionChanged,
    onClickResetButton,
    onApplyFilterClick
  } = props;

  const getSelectedMessage = (
    selectedItems = [],
    allItems = [],
    localeMessage = {}
  ) => {
    const isAllSelected = selectedItems[0] === -1;

    return isAllSelected || selectedItems.length === allItems.length
      ? formatMessage(localeMessage, {
          noOfItems: `${formatMessage(Locale.known_issues_all)} ${
            allItems.length
          }`
        })
      : formatMessage(localeMessage, {
          noOfItems: selectedItems.length
        });
  };

  return (
    <>
      <div>
        <Label
          automationName="selectedProductFiltersbtn"
          value={getSelectedMessage(
            savedFilterState.partnerProducts,
            knownIssuesProductFilters,
            Locale.known_issues_product_selected
          )}
          className={css.filterLabel}
          isLink
        />
        <DynamicMultiSelect
          automationName="productsFilter"
          items={knownIssuesProductFilters}
          onChange={handleProductSelectionChange}
          value={savedFilterState.partnerProducts}
          placeholder={formatMessage(Locale.known_issues_products_placeholder)}
          selectAllText={formatMessage(
            Locale.known_issues_products_select_all_text
          )}
          allPillText="All"
        />
      </div>
      <div className={css.components}>
        <Label
          automationName="selectedComponentFiltersbtn"
          value={getSelectedMessage(
            savedFilterState.componentFilter,
            componentFilters,
            Locale.known_issues_component_selected
          )}
          className={css.filterLabel}
          isLink
        />
        <DynamicMultiSelect
          automationName="componentsFilter"
          items={componentFilters ? componentFilters : []}
          onChange={handleComponentSelectionChange}
          value={savedFilterState.componentFilter}
          placeholder={formatMessage(
            Locale.known_issues_components_placeholder
          )}
          selectAllText={formatMessage(
            Locale.known_issues_components_select_all_text
          )}
          allPillText="All"
        />
      </div>
      <div className={css.statusesMultiSelect}>
        <Label
          automationName="selectedStatusesFiltersbtn"
          value={getSelectedMessage(
            savedFilterState.statusFilter,
            knownIssuesStatusFilters,
            Locale.known_issues_statuses_selected
          )}
          className={css.filterLabel}
          isLink
        />
        <DynamicMultiSelect
          automationName="statusesFilter"
          items={knownIssuesStatusFilters}
          onChange={handleStatusSelectionChange}
          value={savedFilterState.statusFilter}
          placeholder={formatMessage(Locale.known_issues_statuses_placeholder)}
          selectAllText={formatMessage(
            Locale.known_issues_statuses_select_all_text
          )}
          allPillText="All"
        />
      </div>
      <div className={css.fixVersionMultiselect}>
        <Label
          automationName="selectedfixVersionFiltersbtn"
          value={getSelectedMessage(
            savedFilterState.fixVersionFilter,
            fixVersionFilters,
            Locale.known_issues_fixversion_selected
          )}
          className={css.filterLabel}
          isLink
        />
        <DynamicMultiSelect
          automationName="fixVersionsFilter"
          items={fixVersionFilters ? fixVersionFilters : []}
          onChange={handleFixVersionSelectionChange}
          value={savedFilterState.fixVersionFilter}
          placeholder={formatMessage(
            Locale.known_issues_fix_version_placeholder
          )}
          selectAllText={formatMessage(
            Locale.known_issues_fix_version_select_all_text
          )}
          allPillText="All"
        />
      </div>
      <div className={css.myWatchedIssuesMultiselect}>
        <Label
          automationName="myWatchedIssueFilterLabel"
          value={getSelectedMessage(
            savedFilterState.myWatchedIssuesFilter,
            myWatchedIssueFilters,
            Locale.known_issues_my_watched_issues
          )}
          className={css.filterLabel}
          isLink
        />
        <DynamicMultiSelect
          automationName="myWatchedIssuesFilter"
          items={myWatchedIssueFilters}
          onChange={handleMyWatchedIssuesSelectionChanged}
          value={savedFilterState.myWatchedIssuesFilter}
          placeholder={formatMessage(
            Locale.known_issues_my_watched_issues_placeholder
          )}
          selectAllText={formatMessage(Locale.select_all)}
          allPillText="All"
        />
      </div>
      <Flexbox verticalAlign className={css.filteractionPane}>
        <ButtonLight
          className={css.filterActionButton}
          automationName="resetBtnKnownIssue"
          value={formatMessage(Locale.reset)}
          onClick={onClickResetButton}
          isSmall
        />
        <ButtonStandard
          className={css.filterActionButton}
          automationName="applyBtnKnownIssue"
          value={formatMessage(Locale.apply)}
          onClick={onApplyFilterClick}
          isSmall
        />
      </Flexbox>
    </>
  );
};

export class IssuesFilterRequest {
  products;
  components;
  statuses;
  myWatchedIssues;
  fixVersions;
  searchBy;
  isDefault;
}

const KnownIssuesFilterConditions = props => {
  const [isShowTicketFilter, setIsShowTicketFilter] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isShowTextFilter, setIsShowTextFilter] = useState(false);
  const [componentFilters, setComponentFilters] = useState([]);
  const [fixVersionFilters, setfixVersionFilters] = useState([]);
  const { knownIssuesFilters } = props;

  /** @type {IssuesFilterDefinition} */
  const savedFilterState = props.savedFilterState;

  const [PrevsearchText, setPrevSearchText] = useState('');

  const onHelpLinkClick = () => {
    const helpLink =
      'https://docs.connectwise.com/ConnectWise_Documentation/ConnectWise_Unified_Product/ConnectWise_Home/ConnectWise_Home_-_Known_Issues';
    window.open(helpLink, '_blank');
  };

  const composeRequestFilter = isDefaultRequest => {
    let searchBy = searchText ? searchText : '';
    setPrevSearchText(searchText);

    const filters = new IssuesFilterRequest();
    filters.products = composeFilterSelectedItems(
      savedFilterState.partnerProducts,
      PRODUCTS
    );
    filters.components = composeFilterSelectedItems(
      savedFilterState.componentFilter,
      COMPONENTS
    );
    filters.statuses = composeFilterSelectedItems(
      savedFilterState.statusFilter,
      STATUSES
    );
    filters.myWatchedIssues = composeFilterSelectedItems(
      savedFilterState.myWatchedIssuesFilter,
      MYWATCHEDISSUE
    );
    filters.fixVersions = composeFilterSelectedItems(
      savedFilterState.fixVersionFilter,
      FIXVERSION
    );
    filters.searchBy = searchBy;
    filters.isDefault =
      PrevsearchText !== searchText ? false : isDefaultRequest;

    return filters;
  };

  const searchIssues = () => {
    const filters = composeRequestFilter(true);
    props.onSearch(filters);
  };

  // default value false for activefilter
  let activeFilter = false;
  const userId = getManageUserId();
  const userDefaultFilterCookie = getCookie(
    'issues-user-default-filter' + userId
  );
  const userDefaultFilter = JSON.parse(decodeURIComponent(userDefaultFilterCookie));
  if (
    userDefaultFilter !== null &&
    Object.keys(userDefaultFilter.products).length > 0
  ) {
    activeFilter =
      userDefaultFilter.products.length >= 1 ||
      userDefaultFilter.commponents.length >= 1 ||
      userDefaultFilter.statuses.length >= 1 ||
      userDefaultFilter.fixVersions.length >= 1 ||
      userDefaultFilter.myWatchedIssues.length >= 1;
  }

  //call search api
  const [debouncedOnSearch] = useDebouncedCallback(searchIssues, 500);
  useEffect(() => {
    debouncedOnSearch();
  }, [searchText, debouncedOnSearch]);

  const knownIssuesProductFilters = knownIssuesFilters.products
    ? knownIssuesFilters.products
    : [];

  const knownIssuesStatusFilters = knownIssuesFilters.statuses
    ? knownIssuesFilters.statuses
    : [];

  const myWatchedIssueFilters = knownIssuesFilters.myWatchedIssueFilters
    ? knownIssuesFilters.myWatchedIssueFilters
    : [];

  const onApplyFilterClick = () => {
    const filters = composeRequestFilter(false);
    const userId = getManageUserId();
    setCookie(
      'issues-user-default-filter' + userId,
      encodeURIComponent(JSON.stringify(filters)),
      365 * 100
    );

    props.onSearch(filters);
    setIsShowTicketFilter(false);
  };

  const onFilterIconClick = () => {
    setIsShowTicketFilter(true);
  };

  useEffect(() => {
    let localPartnerProducts = [];

    if (
      !savedFilterState.partnerProducts ||
      savedFilterState.partnerProducts.length === 0
    ) {
      setComponentFilters(knownIssuesFilters.components);
      setfixVersionFilters(knownIssuesFilters.fixVersions);
      return;
    }

    //if All products of selected
    if (
      savedFilterState.partnerProducts.length === 1 &&
      savedFilterState.partnerProducts[0] === -1
    ) {
      const allProducts = knownIssuesFilters.products
        ? knownIssuesFilters.products
        : [];
      localPartnerProducts = allProducts.map(item => {
        return item.id;
      });
    } else {
      localPartnerProducts = savedFilterState.partnerProducts;
    }

    let filteredComponents = [];
    //filter component those have product selected
    if (typeof knownIssuesFilters.components !== 'undefined') {
      localPartnerProducts.forEach(item => {
        knownIssuesFilters.components.forEach(component => {
          if (
            component.products.includes(item) &&
            !filteredComponents.includes(component)
          ) {
            filteredComponents.push(component);
          }
        });
      });
    }

    setComponentFilters(filteredComponents);

    //filter component those have product selected
    let filteredFixVersions = [];
    if (typeof knownIssuesFilters.fixVersions !== 'undefined') {
      localPartnerProducts.forEach(item => {
        knownIssuesFilters.fixVersions.forEach(fixVersion => {
          if (
            fixVersion.products.includes(item) &&
            !filteredFixVersions.includes(fixVersion)
          ) {
            filteredFixVersions.push(fixVersion);
          }
        });
      });
    }

    setfixVersionFilters(filteredFixVersions);
  }, [savedFilterState.partnerProducts, knownIssuesFilters]);

  const applyFilterToFlyout = filter => {
    props.onProductSelectionChanged(filter.products);
    props.onComponentSelectionChanged(filter.components);
    props.onStatusSelectionChanged(filter.statuses);
    props.onFixVersionSelectionChanged(filter.fixVersions);
    props.onMyWatchedIssuesSelectionChanged(filter.myWatchedIssues);

    setSearchText('');
  };

  const onClickResetButton = () => {
    const filter = {
      products: [],
      components: [],
      statuses: [],
      fixVersions: [],
      searchBy: '',
      isDefault: true,
      myWatchedIssues: []
    };

    applyFilterToFlyout(filter);
    const userId = getManageUserId();
    setCookie('issues-user-default-filter' + userId, false, -1);

    props.onSearchTextChange('');
    props.onSearch(filter);
    setIsShowTicketFilter(false);
  };

  const handleSelection = (selectedItems, allItems, changeEvent, filter) => {
    if (
      selectedItems[0] === -1 &&
      selectedItems.length !== 1 &&
      selectedItems.length !== allItems.length + 1
    ) {
      let localselectedItems = selectedItems;
      localselectedItems.splice(0, 1);
      changeEvent(localselectedItems);
    } else if (
      (selectedItems[0] === -1 && selectedItems.length === 1) ||
      selectedItems.length === allItems.length
    ) {
      if (
        selectedItems[0] !== allItems[0] &&
        selectedItems[0] === -1 &&
        filter.length !== 0 &&
        changeEvent.name === 'onMyWatchedIssuesSelectionChanged'
      ) {
        selectedItems.length = 0;
        changeEvent(selectedItems);
      } else {
        let localselectedItems = [-1];
        allItems.forEach(element => {
          localselectedItems.push(element.id);
        });
        changeEvent(localselectedItems);
      }
    } else if (
      selectedItems[0] === -1 &&
      selectedItems.length === 1 &&
      selectedItems[0] === allItems[0]
    ) {
      let localselectedItems = selectedItems.concat();
      localselectedItems.splice(0, 1);
      changeEvent(localselectedItems);
    } else {
      changeEvent(selectedItems);
    }
  };

  const handleComponentSelectionChange = selectedItems => {
    handleSelection(
      selectedItems,
      componentFilters,
      props.onComponentSelectionChanged,
      props.savedFilterState.componentFilter
    );
  };

  const handleProductSelectionChange = selectedItems => {
    handleSelection(
      selectedItems,
      knownIssuesProductFilters,
      props.onProductSelectionChanged,
      props.savedFilterState.partnerProducts
    );
  };

  const handleStatusSelectionChange = selectedItems => {
    handleSelection(
      selectedItems,
      knownIssuesStatusFilters,
      props.onStatusSelectionChanged,
      props.savedFilterState.statusFilter
    );
  };

  const handleFixVersionSelectionChange = selectedItems => {
    handleSelection(
      selectedItems,
      fixVersionFilters,
      props.onFixVersionSelectionChanged,
      props.savedFilterState.fixVersionFilter
    );
  };

  const handleMyWatchedIssuesSelectionChanged = selectedItems => {
    handleSelection(
      selectedItems,
      myWatchedIssueFilters,
      props.onMyWatchedIssuesSelectionChanged,
      props.savedFilterState.myWatchedIssuesFilter
    );
  };

  const filterFlyout = (
    <FilterContent
      savedFilterState={savedFilterState}
      knownIssuesProductFilters={knownIssuesProductFilters}
      handleProductSelectionChange={handleProductSelectionChange}
      componentFilters={componentFilters}
      handleComponentSelectionChange={handleComponentSelectionChange}
      knownIssuesStatusFilters={knownIssuesStatusFilters}
      handleStatusSelectionChange={handleStatusSelectionChange}
      fixVersionFilters={fixVersionFilters}
      handleFixVersionSelectionChange={handleFixVersionSelectionChange}
      myWatchedIssueFilters={myWatchedIssueFilters}
      handleMyWatchedIssuesSelectionChanged={
        handleMyWatchedIssuesSelectionChanged
      }
      onClickResetButton={onClickResetButton}
      onApplyFilterClick={onApplyFilterClick}
    />
  );

  const onSearchTextChange = value => {
    setSearchText(value);
    props.onSearchTextChange(value);
  };

  const onSearchIconClickInMobile = () => {
    setIsShowTextFilter(!isShowTextFilter);
    setIsShowTicketFilter(false);
  };

  const onSearchQueryClose = () => {
    setSearchText('');
    props.onSearchTextChange('');
    setIsShowTextFilter(!isShowTextFilter);
    setIsShowTicketFilter(false);
  };

  if (props.isMobileLayout) {
    return (
      <MobileKnownIssuesFilterConditions
        isShowTicketFilter={isShowTicketFilter}
        isShowTextFilter={isShowTextFilter}
        isMobileLayout={props.isMobileLayout}
        activeFilter={activeFilter}
        onSearchTextChange={onSearchTextChange}
        onSearchQueryClose={onSearchQueryClose}
        searchText={searchText}
        onFilterIconClick={onFilterIconClick}
        ticketFlyoutDismissContext={filterFlyout}
        onSearchIconClickInMobile={onSearchIconClickInMobile}
      />
    );
  } else {
    return (
      <DesktopKnownISsuesFilterConditions
        isShowTicketFilter={isShowTicketFilter}
        activeFilter={activeFilter}
        onFilterIconClick={onFilterIconClick}
        ticketFlyoutDismissContext={filterFlyout}
        onSearchTextChange={onSearchTextChange}
        searchText={searchText}
        onHelpLinkClick={onHelpLinkClick}
      />
    );
  }
};

KnownIssuesFilterConditions.propTypes = {
  onSearch: PropTypes.func,
  knownIssuesProductFilters: PropTypes.array
};

export default injectIntl(MobileLayoutHOC(KnownIssuesFilterConditions));
